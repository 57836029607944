/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.show-xs-only {
  display: none !important;
}

@media (max-width: 767px) {
    .show-xs-only {
        display: block !important;
    }
}